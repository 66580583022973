import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Layout, ContactForm } from '../components';
import { Container } from '../styles/shared';
import config from '../../config/website';

const Wrapper = styled.div`
  margin: 0 auto;
  width: 50%;
  @media (max-width:1150px) {
    width: 75%;
    }
`;

const Title = styled.h3``;

const Contact = () => (
  <Layout>
    <Helmet title={`Contact | ${config.siteTitle}`} />
    <Wrapper>
      <Container>
        <div>
          <Title>Send me a message:</Title>
        </div>
        <ContactForm />
      </Container>
    </Wrapper>
  </Layout>
);

export default Contact;
